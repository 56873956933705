/* eslint-disable import/prefer-default-export */
import { filterPreviewContent } from './filter-preview-content'
import { translateListingPostUrls } from "../utils/url-translations"

export const prepareMoreStoriesNodes = (postIds, graphqlData, urlTranslations) => {
  const nodes = filterPreviewContent([
    ...graphqlData.allWpExplore.nodes,
    ...graphqlData.allWpInnovate.nodes,
    ...graphqlData.allWpInspire.nodes,
    ...graphqlData.allWpDrive.nodes,
    ...graphqlData.allWpShortStory.nodes,
  ])
  let moreStoriesPosts = []
  // do this to retain order
  postIds.forEach(id => {
    const found = nodes.filter(post => post.databaseId === id)
    if (found.length) {
      moreStoriesPosts.push(found[0])
    }
  })

  moreStoriesPosts = translateListingPostUrls(moreStoriesPosts, urlTranslations.contentUrlTranslations)

  return moreStoriesPosts
}
