/* eslint-disable import/prefer-default-export */
// https://www.gatsbyjs.com/docs/graphql-reference/#complete-list-of-possible-operators
// The OR graphql filter isn't interpreted by Gatsby :(
// So filtering posts tagged with preview this way
const filterPreviewContent = (posts) => {
  // IMPORTANT!
  // Prefix env variable with 'GATSBY_'
  // Otherwise it won't be available during browsing will return as undefined
  // Prefixing with 'GATSBY_' seems to "fix" it and make it available
  // https://answers.netlify.com/t/environment-variables-returning-undefined/28756
  const isPreviewSite = (parseInt(process.env.GATSBY_IS_PREVIEW_SITE + '', 10) === 1)

  const filtered = posts.filter((item, index) => {
    const current = (item.post) ? item.post : item

    if (!current.tags.nodes.length) {
      return true
    }

    const hasPreviewTag = current.tags.nodes.filter(tag => tag.name.toLowerCase().includes('preview')).length
    if (!isPreviewSite && hasPreviewTag) {
      return false
    } else {
      return true
    }
  })

  return filtered
}

module.exports = { filterPreviewContent }

