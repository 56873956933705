/* eslint-disable import/prefer-default-export */

const findTranslation = (toFind, translations) => {
  // console.log('findTranslation!', toFind, translations)
  const t = translations.filter(item => item.name === toFind)

  let slug = ''
  if (t.length) {
    slug = t.shift().text
  }

  return slug
}

const getContentTypeSlugUrlTranslation = (contentType, translations) =>
  findTranslation(`${contentType}-slug`, translations)

const getContentTypeListingUrlTranslation = (contentType, translations) =>
  findTranslation(`${contentType}-listing`, translations)

const contentTypeFromPostNodeType = nodeType =>
  nodeType.toLowerCase().replace('_', '')

const translatePostUrl = (post, toReplace, translatedSlug) => {
  const translated = post.uri.replace(`/${toReplace}/`, `/${translatedSlug}/`)

  const newPost = { ...post }
  newPost.uri = translated
  return newPost
}

const translateListingPostUrls = (posts, translations) => {
  const transformed = posts.map(item => {
    if (!!item && item.nodeType && item.nodeType !== 'Promo_panel') {
      const postType = contentTypeFromPostNodeType(item.nodeType)

      const translatedSlug = getContentTypeSlugUrlTranslation(
        postType,
        translations
      )
      if (translatedSlug) {
        return translatePostUrl(item, postType, translatedSlug)
      }
    }
    return item
  })
  return transformed
}

module.exports = {
  contentTypeFromPostNodeType,
  getContentTypeSlugUrlTranslation,
  getContentTypeListingUrlTranslation,
  translateListingPostUrls,
  translatePostUrl,
}
